import AOS from "aos";
import axios from "axios";
import Masonry from "masonry-layout";
import LazyLoad from "vanilla-lazyload";
import lottie from 'lottie-web';
import WaveSurfer from 'wavesurfer.js';
// import Player from '@vimeo/player';
import "./scrollNav.min.js";
import "../style.scss";
import "./jobOpenings";
import "./caseStudies";
import "./vueComponents";
import "./careers";
import "./textFixes";
import "./cs2023";
import "./ideas-2023";
import "./webflowBlink";
import "./components/logo-carousel";
import "./components/expanded-card-row";
import "./components/scale-cta";
import "./components/sticky-nav";
import "./components/accordion";
import "./components/faq-carousel";

/* Mobile Nav Menu Toggle */

function toggleMobileNav() {
    document.getElementById("MobileNavWrapper").classList.toggle('mobile-nav-hide');
    document.getElementById("MobileNavWrapper").classList.toggle('mobile-nav-show');
}

console.log('yipppp454');


console.log('yooowwwwww4');


const mobileNavWrapper = document.getElementById("MobileNavWrapper");
mobileNavWrapper.addEventListener('click', toggleMobileNav);


function initFilterDropdown() {
    let dropdownWrapper = document.getElementsByClassName("dropdown-filter");

    for (let index = 0; index < dropdownWrapper.length; index++) {
        let dropdown = dropdownWrapper[index];
        let dropdownButton = dropdown.firstElementChild;
        let dropdownList = dropdown.lastElementChild;
        let dropdownItems = dropdownList.children;
        
        dropdownList.classList.add('dn');

        // Toggle show/hide of dropdown list
        dropdownButton.addEventListener('click', (e) => {
            e.stopPropagation();
            dropdownList.classList.toggle('dn');

            // Handle item click
            for (let index = 0; index < dropdownItems.length; index++) {
                let downdownItem = dropdownItems[index];
                
                // Handle update text
                downdownItem.addEventListener('click', (e) => {
                    dropdownButton.firstChild.innerHTML = e.target.textContent;  
                });
            }
        });

        document.addEventListener('click', (e) => {
            closeAllDropdowns();
        })
    }
}

function closeAllDropdowns(){

        let dropdownWrapper = document.getElementsByClassName("dropdown-filter");
        for (let index = 0; index < dropdownWrapper.length; index++) {
            let dropdownList = dropdownWrapper[index].lastElementChild;
            dropdownList.classList.add('dn');
        }
}

if (document.getElementsByClassName("dropdown-filter")) {
    initFilterDropdown();
}

AOS.init();

var lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy"
});

// Create an instance
let wavesurfer = {};
let wavesurferWithOptions;

// Init & load audio file
if (document.querySelector('#waveform')) {
    
    document.addEventListener('DOMContentLoaded', function() {
        let playButton = document.querySelector('#playBtn'),
        skipForwardButton = document.querySelector('#skipForwardBtn'),
        skipBackwardButton = document.querySelector('#skipBackwardBtn'),
        currentTime = document.querySelector('#wavesurfer-duration'),
        container = document.querySelector('#waveform');
    
        // Get audio clip from data attribute
        let audioClipUrl = container.dataset.audio;
    
        var formatTime = function (time) {
            return [
                Math.floor((time % 3600) / 60), // minutes
                ('00' + Math.floor(time % 60)).slice(-2) // seconds
            ].join(':');
        };
    
        wavesurfer = WaveSurfer.create({
            container: container,
            hideScrollbar: true,
            responsive: true,
            fillParent: true,
            barWidth: 2,
            barHeight: 2,
            barGap: null,
            waveColor: '#C4C4C4',
            progressColor: '#00BBDD',
            cursorWidth: 0,
            height: 96
        });
    
        wavesurfer.on('error', function(e) {
            console.warn(e);
        });
    
        wavesurfer.once('ready', function() {
            playButton.onclick = function() {
                
                wavesurfer.playPause();
    
                Array.from(this.children).forEach((item) => {
                    item.classList.toggle('dn');
                });
            };
    
            skipForwardButton.onclick = function() {
    
                if ( (wavesurfer.getCurrentTime() + 30) < wavesurfer.getDuration() ) {
                    wavesurfer.skipForward(30)
                }
            };
    
            skipBackwardButton.onclick = function() {
                wavesurfer.skipBackward(30);
            };
    
            wavesurfer.on("finish", function() {
                Array.from(playButton.children).forEach((item) => {
                    item.classList.toggle('dn');
                });
            });
    
            currentTime.innerHTML = formatTime(wavesurfer.getDuration());
        });
    
        // Show current time
        wavesurfer.on('audioprocess', function () {
            currentTime.innerHTML = formatTime(wavesurfer.getCurrentTime());
        });
    
        // Load audio from URL
        wavesurfer.load(audioClipUrl);
    });
}

if (document.querySelector('.load-on-click')) {
    let parentEl = document.querySelector('.load-on-click');
    let loadButton = parentEl.querySelector('.ltv-playbtn');
    // let vimeoTemplate = parentEl.getElementById('vimeo-source');

    //constuct the vimeo iframe
    let vimeoTemplate = document.createElement('div');
    let vimeoId = parentEl.dataset.blinkVimeoId;
    vimeoTemplate.innerHTML = `
    <iframe src="https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay"></iframe>
`;

    // insert the vimeo iframe and autoplay it
    // vimeoTemplate.addEventListener('', function() {


    loadButton.addEventListener('click', function() {
        let vimeoPlayer = document.createElement('div');
        vimeoPlayer.classList.add('iframe-wrapper');
        vimeoPlayer.innerHTML = vimeoTemplate.innerHTML;
        parentEl.replaceWith(vimeoPlayer);
    });


};

//TODO: convert to handle multiple vimeo players on one page

// document.addEventListener('DOMContentLoaded', function() {
//     let vimeoPlayer = document.getElementById('VimeoHero');
    
//     if (vimeoPlayer) {
//         // console.log('vimeo player');
//         let iframeWrapper = vimeoPlayer.querySelector('.iframe-wrapper');
//         let hasPlayed = false;  
//         const iframe = vimeoPlayer.querySelector('iframe');
//         let player = new Player(iframe);  
    
//         const togglePlay = () => {
//             if (!hasPlayed) {
//                 iframeWrapper.classList.remove('unplayed');
//                 hasPlayed = true;
//             }
        
//             let isPaused = player.getPaused().then(function(paused) {
//                 if (paused) {
//                     player.play();
//                 } 
//                 // else {
//                 //     player.pause();
//                 // }
//             });
//         }
    
//         iframeWrapper.addEventListener('click', togglePlay);
    
//     }
// });

if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    }); 
  }




// var msnry = new Masonry( '.msnry-grid', {});


// console.log('cs2023');
// lottie.loadAnimation({
//   container: document.getElementById('cs2023-explode'),
//   renderer: 'svg',
//   loop: true,
//   autoplay: true,
//   path: '/assets/temp/data.json',
// });