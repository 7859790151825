const store = {
  debug: true,
  state: {
    showModal: false,
    dots: [],
    dotGrid: null
  },
  updateModalAction(showModalState) {
    if (this.debug) console.log("toggleModalAction triggered")
    if (showModalState === undefined) {
      showModalState = !this.state.showModal
    }
    if (showModalState !== undefined) {
      document.body.classList.toggle('js-modal-open');
    }
    this.state.showModal = showModalState
  },
  setDotGrid(grid) {
    this.state.dotGrid = grid
  }
}

export default store
