function caseStudyUrl() {
  if(window.location.pathname === '/work/helping-scientists-turn-data-into-information') {
    document.body.classList.add('is-nasa');
  }
  if(window.location.pathname === '/work/designing-delightful-iot-shower-experience') {
    document.body.classList.add('is-moen');
  }
  if(window.location.pathname === '/work/expressing-legacy-design-inside-and-out') {
    document.body.classList.add('is-bando');
  }
  if(window.location.pathname === '/work/designing-an-intuitive-cyber-security-platform') {
    document.body.classList.add('is-logrhythm');
  }
  if(window.location.pathname === '/work/cs2023') {
    document.body.classList.add('is-cs2023');
  }
}

function detectCs2023() {
  if (document.querySelector('.cs--cs2023')) {
    document.body.classList.add('is-cs2023');
  }
}

caseStudyUrl();
detectCs2023(); 