import axios from "axios"

function getJobData() {
  axios({
    method: "get",
    url: "https://api.lever.co/v0/postings/blinkux?mode=json",
    responseType: "stream"
  }).then(
    response => {
      // send job data and filtered commitment types to output
      // console.log(response.data);
      outputJobData(response.data, getFilterData(response.data))
    },
    error => {
      return error
    }
  )
}

// This function takes the response data and returns the commitment types and their occurances
// ex. returns {Full-time: 7, Contacts: 5, Part-time, 1}
function getFilterData(data) {
  let jobFilters = { 'commitments': [], 'locations': [], 'departments': [] }
  // Commitment type are Full-time, Contract, or Part-time



  // Create array of job names
  data.forEach(job => {
    jobFilters.commitments.push(job.categories.commitment);
    jobFilters.locations.push(job.categories.location);
    jobFilters.departments.push(job.categories.team);

    if (job.categories.department != "Blink"){
      jobFilters.departments.push(job.categories.department);
    }
  })

  jobFilters.commitments = [...new Set(jobFilters.commitments)]
  jobFilters.locations = [...new Set(jobFilters.locations)]
  jobFilters.departments = [...new Set(jobFilters.departments)]

  return jobFilters;
}

function outputJobData(jobs, filters) {
  let filtersLocationElem = document.getElementById('filtersLocation').querySelector(".careers-filters")
  let filtersCommitmentElem = document.getElementById('filtersCommitment').querySelector(".careers-filters")
  let jobsOpeningsSection = document.querySelector(".careers-job-listings")

  filtersCommitmentElem.innerHTML = "<li data-filter-type='all-types'><button>All Types</button></li>";

  // output filter buttons to filters section
  for (let key in filters.commitments) {
    filtersCommitmentElem.innerHTML += `<li data-filter-type="${filters.commitments[key]}"><button>${filters.commitments[key]}</button></li>`
  }

  for (let key in filters.locations) {
    filtersLocationElem.innerHTML += `<li data-filter-location="${filters.locations[key]}"><button>${filters.locations[key]}</button></li>`
  }


  filters.departments.forEach(department => {
    let teamSection = document.createElement('section');
    let jobsWrapper = document.createElement('div')
    let teamTitle = document.createElement('h3');
    
    teamSection.id = department;
    teamTitle.textContent = department;
    teamSection.appendChild(teamTitle);
    teamSection.appendChild(jobsWrapper);
    jobsOpeningsSection.appendChild(teamSection);
    
    // // output job postings to jobs section
    jobs.forEach(job => {
      if ( (department == "Embedded" && job.categories.department == "Embedded") || (job.categories.team == department && job.categories.department != "Embedded")) {
        jobsWrapper.innerHTML += `
          <a href="${job.hostedUrl}" target="_blank" class="careers-job"
          data-filter-type="${job.categories.commitment}" 
          data-filter-location="${job.categories.location}">
            <h4>${job.text}</h4>
            <p class="color-light-gray mv0">${job.categories.location}</p>
          </a>`;
      }
    })

    if (teamSection.lastChild.innerHTML.length == 0){
      teamSection.remove()
    }
  })




  handleFilterClick()
}

function handleFilterClick() {
  // Default filter options
  let currentCommitment = 'all-types';
  let currentLocation = 'All Locations';
  let filterLists = document.querySelectorAll(".careers-filters")

  filterLists.forEach(function (filterList) {

    filterList.childNodes.forEach(function (filter) {

      filter.addEventListener("click", function (e) {
        let allJobs = document.querySelectorAll(".careers-job");

        if(this.classList.contains('active')) {
          this.classList.remove('active');
        }
        else {
          filterList.querySelectorAll('.active').forEach(function (activeEl) {
            activeEl.classList.remove('active');
          })

          this.classList.add('active');
        }

        if (this.dataset.filterType)
          currentCommitment = this.dataset.filterType;
        else if (this.dataset.filterLocation)
          currentLocation = this.dataset.filterLocation;

        allJobs.forEach(job => {

          if (currentCommitment == 'all-types' && currentLocation == 'All Locations') {

            job.classList.remove("filter-hide")

          }
          else if (currentCommitment == 'all-types') {

            if (job.dataset.filterLocation == currentLocation)
              job.classList.remove("filter-hide")
            else
              job.classList.add("filter-hide")

          }
          else {

            if (currentLocation == 'All Locations') {

              if ((job.dataset.filterType == currentCommitment))
                job.classList.remove("filter-hide")
              else
                job.classList.add("filter-hide")

            }
            else {

              if ((job.dataset.filterType == currentCommitment) && (job.dataset.filterLocation == currentLocation))
                job.classList.remove("filter-hide")
              else
                job.classList.add("filter-hide")

            }
          }
        })
      })
    })
  })
}


if (document.getElementById('CareerOpenings')) {
  getJobData()
}

