import EmblaCarousel from "embla-carousel"

const faqCarousels = document.querySelectorAll(".faq-section-related")

if (faqCarousels) {
  for (let i = 0; i < faqCarousels.length; i++) {
    const carousel = faqCarousels[i]
    const embla = EmblaCarousel(carousel, {  
        breakpoints: {
            '(min-width: 768px)': {
                active: false,
            },
        },
     })
  }
}
