import EmblaCarousel from 'embla-carousel';
import Autoscroll from 'embla-carousel-auto-scroll';

const logoCarousels = document.querySelectorAll('.logo-carousel');

if (logoCarousels.length) {
    console.log('theres a logo carousel here');

    for (let i = 0; i < logoCarousels.length; i++) {
        const carousel = logoCarousels[i];
        const speed = carousel.dataset.speed || .5;
        const plugins = [Autoscroll({
            playOnInit: true,
            speed: speed,
        })];
        const embla = EmblaCarousel(carousel, {
            loop: true,
            dragFree: true,
            containScroll: 'trimSnaps',
        },
        plugins
    );

        // const prevButton = carousel.querySelector('.embla__button--prev');
        // const nextButton = carousel.querySelector('.embla__button--next');

        // prevButton.addEventListener('click', embla.scrollPrev, false);
        // nextButton.addEventListener('click', embla.scrollNext, false);
    }

}