// import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import scrollNav from "scrollnav";

const stickyNavHeadings = document.querySelectorAll(".sticky-nav-anchor")
const stickyNavLinks = document.querySelectorAll(".sticky-nav-link")
const stickyNavInner = document.querySelector(".sticky-nav-inner")
const stickyNavPreheading = document.querySelector(".sticky-nav-preheading");

if (stickyNavHeadings.length) {

  const stickyContent = document.querySelector(".content-blocks");

  scrollNav.init(stickyContent, {
    sections: ".sticky-nav-anchor",
    insertTarget: stickyNavPreheading,
    insertLocation: "after",
    scrollOffset: 0,

});




  // old handling
  // const changeStickyNavActive = () => {
  //   let current = ""

  //   stickyNavHeadings.forEach((section) => {
  //     const sectionTop = section.offsetTop
  //     if (window.scrollY >= (sectionTop + 100)) {
  //       current = section.getAttribute("id")
  //     }
  //   })

  //   stickyNavLinks.forEach((li) => {
  //     li.classList.remove("active")
  //     // console.log(li)
  //     if (li.href && li.href.split("#")[1] === current) {
  //       li.classList.add("active")
  //     }
  //   })
  // }

  // window.addEventListener("scroll", throttle(changeStickyNavActive, 50))

}
//old version of sticky nav - intersection observer. might revisit
// if (stickyNavHeadings.length) {
//   // const watchStickyNavHeading = throttle(entry) {
//   //     if ((entry.boundingClientRect.top - window.scrollY) < 150) {
//   //         console.log(entry.boundingClientRect.top, window.scrollY, entry.boundingClientRect.top - window.scrollY);
//   //         console.log(entry, 'entry');
//   //         console.log(entry.target.id);
//   //         // window.location.hash = entry.target.id;
//   //         stickyNavLinks.forEach(link => {
//   //             link.classList.remove('active');
//   //             if (link.getAttribute('href').slice(1) === entry.target.id) {
//   //                 link.classList.add('active');
//   //             }
//   //         });
//   //     }
//   // }

//   let lastScroll = window.scrollY;

//   // a function to determine which direction we're scrolling
//   function getScrollDirection() {
//       let currentScroll = window.scrollY;
//       let scrollDirection = currentScroll > lastScroll ? "down" : "up";
//       lastScroll = currentScroll;
//       return scrollDirection;
//   }

//   // let embla = EmblaCarousel(document.querySelector(".sticky-nav"), {
//   //   loop: true,
//   //   dragFree: true,
//   //   selectedClass: "active",
//   // }
//   // )

//   function watchStickyNavHeading(entry, index = 0) {
//     if (entry.boundingClientRect.top - window.scrollY < 150) {
//       console.log(
//         entry.boundingClientRect.top,
//         window.scrollY,
//         entry.boundingClientRect.top - window.scrollY
//       )
//       console.log(entry, "entry")
//       console.log(entry.target.id)
//       // window.location.hash = entry.target.id;

//       for (let i = 0; i < stickyNavLinks.length; i++) {
//         console.log(stickyNavLinks[i].getAttribute("href").slice(1), entry.target.id);
//         if (stickyNavLinks[i].getAttribute("href").slice(1) === entry.target.id) {
//           // window.location.hash = entry.target.id;
//           document.querySelector(".sticky-nav-link.active")?.classList.remove("active");
//           stickyNavLinks[i].classList.add("active");
//           // scroll the stickyNavInner to the active link
//           // stickyNavInner.scrollTo(0, stickyNavLinks[i].offsetLeft - 100);

//         }
//       }
//       // stickyNavLinks.forEach((link) => {
//       //   // link.classList.remove("active")
//       //   if (link.getAttribute("href").slice(1) === entry.target.id) {
//       //     // link.classList.add("active")
//       //     // stickyNavInner.scrollTo(0, link.offsetLeft - 100);
//       //     embla.scrollTo(stickyNavLinks.indexOf(entry.target));
//       //   }
//       // })
//     }
//   }
//   // create an intersection observer that will trigger when a sticky heading hits the top of the viewport
//   const stickyNavObserver = new IntersectionObserver((entries, observer) => {
//     console.log('entries', entries);
//     const scrollDirection = getScrollDirection();
//     console.log('scrollDirection', scrollDirection);
//     let relevantEntry;

//     if (scrollDirection === "down") {
//        relevantEntry = entries.at(-1);
//     } else {
//        relevantEntry = entries.at(0);
//     }

//     // let lastEntry = entries.at(-1);

//     if (relevantEntry.isIntersecting) {
//       watchStickyNavHeading(relevantEntry);
//     }

//     // watchStickyNavHeading(lastEntry);

//     // if (scrollDirection() === "down") {
//     //   console.log('scrolling down');
//     //   entries.forEach((entry) => {
//     //     if (entry.isIntersecting) {
//     //       watchStickyNavHeading(entry);
//     //     }
//     //   });
//     // }

//     // entries.forEach((entry) => {

//     //   // const stickyNav = document.querySelector('.sticky-nav');
//     //   // const stickyNavLinks = document.querySelectorAll('.sticky-nav-link');

//     //   if (entry.isIntersecting) {
//     //     window.addEventListener("scroll", watchStickyNavHeading(entry))

//     //     // check if the top of the heading is within 150px of the top of the viewport
//     //   } else {
//     //     window.removeEventListener("scroll", watchStickyNavHeading(entry))
//     //   }
//     // })
//   })

//   // observe each sticky heading
//   stickyNavHeadings.forEach((heading) => {
//     stickyNavObserver.observe(heading, { threshold: 0.7 })
//   })
// }
