import EmblaCarousel from "embla-carousel"
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
// import VanillaTilt from "vanilla-tilt";

// import Autoscroll from 'embla-carousel-auto-scroll';

const expandedCardRow = document.querySelectorAll(".expanded-card-row")

if (expandedCardRow.length) {

  for (let i = 0; i < expandedCardRow.length; i++) {
    const carousel = expandedCardRow[i]
    const cards = carousel.querySelectorAll(".expanded-card")
    // let containScroll = cards.length > 2 ? false : 'keepSnaps';
    let containScroll = 'keepSnaps';
    console.log('containScroll', containScroll)

    // const speed = carousel.dataset.speed || .5;
    const plugins = [WheelGesturesPlugin({target: carousel})]
    const embla = EmblaCarousel(
      carousel,
      {
        dragFree: false,
        containScroll: 'keepSnaps',
        align: "start"
      },
      plugins
    )



    // let tiltOptions = {
    //   max: 5,
    //   speed: 100
    // }

    for (let j = 0; j < cards.length; j++) {
      const card = cards[j]

      let closeButton = card.querySelector(".expanded-card-close");

      closeButton.addEventListener("click", (e) => {
        e.stopPropagation()
        console.log("close clicked")
        card.classList.remove("expanded")
        // embla.reInit({containScroll: containScroll})
        embla.scrollTo(j);
      });

      // VanillaTilt.init(card, tiltOptions);

      card.addEventListener("click", (e) => {
        e.stopPropagation()
        console.log("card clicked")
        // card.VanillaTilt.destroy();
        document.querySelectorAll(".expanded-card").forEach((card) => {
          card.classList.remove("expanded");
          // card.VanillaTilt.init(card, tiltOptions);
        })
        console.log('scrolling to', j)
        // embla.reInit({containScroll: containScroll})
        embla.scrollTo(j);
        card.classList.toggle("expanded")
      })
    }

    embla.on("reInit", () => {
      console.log("embla reInit", embla)
    })

    // if (cards.length > 1) {
    //   VanillaTilt.init(cards, {
    //     max: 25,
    //     speed: 400
    //   })
    // }
    // const prevButton = carousel.querySelector('.embla__button--prev');
    // const nextButton = carousel.querySelector('.embla__button--next');

    // prevButton.addEventListener('click', embla.scrollPrev, false);
    // nextButton.addEventListener('click', embla.scrollNext, false);
  }
}



// a deprecated approach, saving here
// import EmblaCarousel from "embla-carousel"
// // import VanillaTilt from "vanilla-tilt";

// // import Autoscroll from 'embla-carousel-auto-scroll';

// const expandedCardRow = document.querySelectorAll(".expanded-card-row")

// if (expandedCardRow.length) {

//   for (let i = 0; i < expandedCardRow.length; i++) {
//     const carousel = expandedCardRow[i]
//     const cards = carousel.querySelectorAll(".expanded-card")
//     let containScroll = cards.length > 2 ? false : 'keepSnaps';
//     containScroll = false;
//     console.log('containScroll', containScroll)

//     // const speed = carousel.dataset.speed || .5;
//     const plugins = []
//     const embla = EmblaCarousel(
//       carousel,
//       {
//         dragFree: true,
//         containScroll: false,
//         align: "start"
//       },
//       plugins
//     )

//     for (let j = 0; j < cards.length; j++) {
//       const card = cards[j]

//       let align = "start"
//       // if (j === 0) {
//       //   align = "start"
//       // // } else if (j === cards.length - 1) {
//       // //   align = "end"
//       // }

//       let closeButton = card.querySelector(".expanded-card-close");

//       closeButton.addEventListener("click", (e) => {

//         e.stopPropagation()
//         console.log("close clicked", align)
//         card.classList.remove("expanded")
//         embla.reInit({containScroll: false, align: align})
//         embla.scrollTo(j);
//       });

//       card.addEventListener("click", (e) => {
//         e.stopPropagation()
//         console.log("card clicked", align)
//         // card.VanillaTilt.destroy();
//         document.querySelectorAll(".expanded-card").forEach((card) => {
//           card.classList.remove("expanded");
//           // card.VanillaTilt.init(card, tiltOptions);
//         })
//         console.log('scrolling to', j)
//         setTimeout(() => {
//           embla.reInit({containScroll: false, align: 'end'})
//           embla.scrollTo(j);
//         }, 1000);

//         card.classList.toggle("expanded")
//       })
//     }

//     embla.on("reInit", () => {
//       console.log("embla reInit", embla)
//     })
//   }
// }
