import lottie from 'lottie-web';
import { TimelineMax, TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

var getJSON = function(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'json';
  xhr.onload = function() {
    var status = xhr.status;
    if (status === 200) {
      callback(null, xhr.response);
    } else {
      callback(status, xhr.response);
    }
  };
  xhr.send();
};


export function csDebounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};



if (document.querySelector('body').classList.contains('is-cs2023')) {
  // console.log('cs2023');

  document.querySelectorAll('.cs__intro-specs-row').forEach(function (el) {
    if (el.querySelector('.cs__intro-specs-title').innerText == 'Visit the Site') {
      el.style.cursor = 'pointer';
      el.addEventListener('click', function () {
        let url = el.querySelector('.cs__intro-specs-text a').href;
        window.open(url, '_blank');
      })
    }
  })

  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


  let controller;
  const explodeContainer = document.getElementById('cs2023-explode');

  if (explodeContainer) {
  const explodeJsonUrl = explodeContainer.dataset.lottieFile;
  const bodyContent = explodeContainer.dataset.lottieBody;
  const subheadContent = explodeContainer.dataset.lottieSubheading;
  const duration = explodeContainer.dataset.lottieDuration;
  const triggerHook = explodeContainer.dataset.lottieTriggerHook;
 
  // don't love this, but it works for the moment
  fetch(explodeJsonUrl)
    .then(response => response.json())
    .then(data => {
      let animText = JSON.stringify(data);
      animText = animText.replace(/bodyContent/g, bodyContent);
      animText = animText.replace(/subheadContent/g, subheadContent);
      return JSON.parse(animText);
    }).then((animationData) => {
      // console.log('animationData', animationData)
      //TODO: this is a hack, need to figure out how to get the path to the images correct
      animationData.assets[0].u = '/assets/anim/explode/images/';
      animationData.assets[1].u = '/assets/anim/explode/images/';
      animationData.assets[2].u = '/assets/anim/explode/images/';
      animationData.fr = 120;
      const explode = lottie.loadAnimation({
        container: document.getElementById('cs2023-explode'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        name: 'explode',
        // path: '/assets/anim/explode/Motion_Asset_01b.json',
        animationData: animationData,
      });

      explode.addEventListener('DOMLoaded', function () {
        let tl = new TimelineMax({ repeat: 0 })
        tl.to({ frame: 0 }, 1, {
          frame: explode.totalFrames - 1,
          onUpdate: function () {
            explode.goToAndStop(Math.round(this.target.frame), true)
          },
          Ease: Linear.easeInOut
        })
    
        // console.log('explode', explode)
    
        controller = new ScrollMagic.Controller();
    
        var scene = new ScrollMagic.Scene({
          triggerElement: ".explode-env",
          // offset: 600,
          triggerHook: triggerHook,
          duration: duration
        }).setTween(tl).setPin("#cs2023-explode").addTo(controller);
    
      })
    
    })

    const explodeContainerMobile = document.getElementById('cs2023-explode-mobile');
    const explodeJsonUrlMobile = explodeContainerMobile.dataset.lottieFile;
    // const bodyContent = explodeContainer.dataset.lottieBody;
    // const subheadContent = explodeContainer.dataset.lottieSubheading;
    const durationMobile = explodeContainer.dataset.lottieDuration;
    const triggerHookMobile = explodeContainer.dataset.lottieTriggerHook;
  
    // don't love this, but it works for the moment
    fetch(explodeJsonUrlMobile)
      .then(response => response.json())
      .then(data => {
        let animText = JSON.stringify(data);
        animText = animText.replace(/bodyContent/g, bodyContent);
        animText = animText.replace(/subheadContent/g, subheadContent);
        return JSON.parse(animText);
      }).then((animationData) => {
        // console.log('animationData', animationData)
        //TODO: this is a hack, need to figure out how to get the path to the images correct
        animationData.assets[0].u = '/assets/anim/explode/images/';
        animationData.assets[1].u = '/assets/anim/explode/images/';
        animationData.assets[2].u = '/assets/anim/explode/images/';
        animationData.fr = 120;
        const explodeMobile = lottie.loadAnimation({
          container: document.getElementById('cs2023-explode-mobile'),
          renderer: 'svg',
          loop: false,
          autoplay: false,
          name: 'explodeMobile',
          // path: '/assets/anim/explode/Motion_Asset_01b.json',
          animationData: animationData,
        });
  
        explodeMobile.addEventListener('DOMLoaded', function () {
          let tlm = new TimelineMax({ repeat: 0 })
          tlm.to({ frame: 0 }, 1, {
            frame: explodeMobile.totalFrames - 1,
            onUpdate: function () {
              explodeMobile.goToAndStop(Math.round(this.target.frame), true)
            },
            Ease: Linear.easeInOut
          })
      
          // console.log('explodeMobile', explodeMobile)
      
          var controllerMobile = new ScrollMagic.Controller();
      
          var sceneMobile = new ScrollMagic.Scene({
            triggerElement: ".explode-env-mobile",
            // offset: 600,
            triggerHook: triggerHookMobile,
            duration: durationMobile
          })
            .setTween(tlm)
            .setPin("#cs2023-explode-mobile")
            .addTo(controllerMobile)
        })
      })
  }

  const stepsContainer = document.querySelector('.cs__steps');

  if (stepsContainer) {
  const stepSlidesContainer = document.querySelector('.cs__steps-slider');
  const stepsTextContainer = document.querySelector('.cs__steps-text');
  let allStepSlides = document.querySelectorAll('.cs__steps-slide:not(.spacer)');
  let allStepTexts = document.querySelectorAll('.cs__steps-text-cell:not(.spacer)');
  let currentStepCount = document.getElementById('current-step');

  let stepsIndex = 0;
  // const tempOffset = 3;

  function runStepContainer(index) {
    // console.log('runStepContainer', index)


    for (let i = 0; i < allStepTexts.length; i++) {
      if (i === index) {
        // setTimeout(function () {
          allStepTexts[i].classList.add('is-active');
        // }, 500);
      } else {
        allStepTexts[i].classList.remove('is-active');
      }
    }

    for (let j = 0; j < allStepSlides.length; j++) {
      if (j === index) {
        setTimeout(function () {
          allStepSlides[j].classList.add('is-active');
        }, 500);
      } else {
        allStepSlides[j].classList.remove('is-active');
      }
    }
  }

  runStepContainer(stepsIndex);

  document.getElementById('slide-prev').addEventListener('click', csDebounce(function () {
    // console.log('prev')
    if (stepsIndex > 0) {
      stepsIndex--;

      runStepContainer(stepsIndex);
      currentStepCount.innerText = stepsIndex + 1;
    }
  }, 300, true))

  document.getElementById('slide-next').addEventListener('click', csDebounce(function () {
    // console.log('next')
    if (stepsIndex < allStepSlides.length - 1) {
      stepsIndex++;

      runStepContainer(stepsIndex);
      currentStepCount.innerText = stepsIndex + 1;
    }
  }, 300, true))
  }


  // countup
  const countupEls = document.querySelectorAll('.countup');
  if (countupEls && countupEls.length > 0) {
  // How long you want the animation to take, in ms
  const animationDuration = 2000;
  // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
  const frameDuration = 1000 / 60;
  // Use that to calculate how many frames we need to complete the animation
  const totalFrames = Math.round(animationDuration / frameDuration);
  // An ease-out function that slows the count as it progresses
  const easeOutQuad = t => t * (2 - t);

  // The animation function, which takes an Element
  const animateCountUp = el => {
    // console.log('ellll', el)
    let frame = 0;
    const countTo = parseInt(el.innerHTML, 10);
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
      frame++;
      // Calculate our progress as a value between 0 and 1
      // Pass that value to our easing function to get our
      // progress on a curve
      const progress = easeOutQuad(frame / totalFrames);
      // Use the progress value to calculate the current count
      const currentCount = Math.round(countTo * progress);

      // If the current count has changed, update the element
      if (parseInt(el.innerHTML, 10) !== currentCount) {
        el.innerHTML = currentCount;
      }

      // If we’ve reached our last frame, stop the animation
      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  // Run the animation on all elements with a class of ‘countup’
  const runAnimations = () => {
    countupEls.forEach(animateCountUp);
    // console.log('runAnimations', countupEls);
  };

  const countUpContainers = document.querySelectorAll('.cs__counter, .counter');

  countUpContainers.forEach(function (el) {

  // If the element is in view, start the animation:
  const countupObserver = new IntersectionObserver(function (entries, countupObserver) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        runAnimations();
        countupObserver.unobserve(entry.target);
      }
    });
  }
  );

  countupObserver.observe(el);
  });
}

  // TODO: get a hold of the default HTML purifier config and doo this there instead
  document.querySelectorAll('.cs__credits a').forEach(function (el) {
    if (el.href.indexOf('http') > -1) {
      el.setAttribute('target', '_blank');
    }

    if (el.href.indexOf('mailto') > -1) {
      el.classList.add('cs__mail-link');
    }

  })
}