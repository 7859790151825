let webflow = document.getElementById('webflow-export');
if (webflow !== null && webflow !== undefined) {
    console.log('webflow import');

    let assetBase = webflow.dataset.assetBase;
    let replacementForm = document.getElementById('replace-form');

    if (replacementForm !== null && replacementForm !== undefined) {
        // there's a freeform we're loading in, and it needs to replace the webflow import's form

        let originalForm = webflow.querySelector('form');
        console.log('og', originalForm);

        let textInput = originalForm.querySelector('input[type="text"]');
        if (textInput !== null && textInput !== undefined) {
            let formTextInputs = replacementForm.querySelectorAll('input[type="text"]');
            if (formTextInputs !== null && formTextInputs !== undefined) {
                formTextInputs.forEach((item) => {
                    item.classList = textInput.classList;
                });
            }
        }

        let textArea = originalForm.querySelector('textarea');
        if (textArea !== null && textArea !== undefined) {
            let formTextAreas = replacementForm.querySelectorAll('textarea');
            if (formTextAreas !== null && formTextAreas !== undefined) {
                formTextAreas.forEach((item) => {
                    item.classList = textArea.classList;
                });
            }
        }

        let phoneInput = originalForm.querySelector('input[type="tel"]');
        if (phoneInput !== null && phoneInput !== undefined) {
            let formPhoneInputs = replacementForm.querySelectorAll('input[type="tel"]');
            if (formPhoneInputs !== null && formPhoneInputs !== undefined) {
                formPhoneInputs.forEach((item) => {
                    item.classList = phoneInput.classList;
                });
            }
        }

        let emailInput = originalForm.querySelector('input[type="email"]');
        if (emailInput !== null && emailInput !== undefined) {
            let formEmailInputs = replacementForm.querySelectorAll('input[type="email"]');
            if (formEmailInputs !== null && formEmailInputs !== undefined) {
                formEmailInputs.forEach((item) => {
                    item.classList = emailInput.classList;
                });
            }
        }

        let submitButton = originalForm.querySelector('input[type="submit"]');
        if (submitButton !== null && submitButton !== undefined) {
            let formSubmitButtons = replacementForm.querySelectorAll('input[type="submit"]');
            if (formSubmitButtons !== null && formSubmitButtons !== undefined) {
                formSubmitButtons.forEach((item) => {
                    item.classList = submitButton.classList;
                });
            }
        }

        let selectInput = originalForm.querySelector('select');
        if (selectInput !== null && selectInput !== undefined) {
            let formSelectInputs = replacementForm.querySelectorAll('select');
            if (formSelectInputs !== null && formSelectInputs !== undefined) {
                formSelectInputs.forEach((item) => {
                    item.classList = selectInput.classList;
                });
            }
        }

        let formLabel = originalForm.querySelector('label');
        if (formLabel !== null && formLabel !== undefined) {
            let formLabels = replacementForm.querySelectorAll('label');
            if (formLabels !== null && formLabels !== undefined) {
                formLabels.forEach((item) => {
                    item.classList = formLabel.classList;
                });
            }
        }

        originalForm.parentNode.replaceChild(replacementForm, originalForm);





    }

            // // find and replace srcset urls in img tags
            // let imgs = webflow.querySelectorAll('img');

            // console.log('imgs', imgs)
    
            // if (imgs !== null && imgs !== undefined) {
            //     imgs.forEach((item) => {
            //         let srcset = item.getAttribute('srcset');
            //         if (srcset !== null && srcset !== undefined) {
            //             let srcsetArray = srcset.split(',');
            //             if (srcsetArray !== null && srcsetArray !== undefined) {
            //                 srcsetArray.forEach((item) => {
            //                     console.log(item)
            //                     let srcsetUrl = item.split(' ')[0];
            //                     let newSrcsetUrl = srcsetUrl.replace('images/', assetBase + '/');
            //                     srcset = srcset.replace(srcsetUrl, newSrcsetUrl);
            //                 });
            //             }
            //             item.setAttribute('srcset', srcset);
            //         }
            //     });
            // }
}