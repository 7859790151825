import Vue from "vue"
// import { createApp } from 'vue'
import s from "./store"

import Parallax from "vue-parallaxy"
// import * as TWEEN from "@tweenjs/tween.js"
// Social
// import blinklogo from "./components/BlinkLogo.vue"
// import linkedin from "./components/Linkedin.vue"
import twitter from "./components/Twitter.vue"
// import iconinstagram from "./components/IconInstagram.vue"
// import facebook from "./components/Facebook.vue"
// import dribbble from "./components/Dribbble.vue"
// import behance from "./components/Behance.vue"
// Icons
// import closebutton from "./components/CloseButton.vue"
// import iconclose from "./components/IconClose.vue"
// import iconmenu from "./components/IconMenu.vue"
// import iconsearch from "./components/IconSearch.vue"
// import iconarrowright from "./components/IconArrowRight.vue"
// import iconarrowrightblack from "./components/IconArrowRightBlack.vue"
// import iconarrowcircle from "./components/IconArrowCircle.vue"
// import iconchevronarrowdown from "./components/IconChevronArrowDown.vue"
// import iconplus from "./components/IconPlus.vue"
// import iconborderclose from "./components/IconBorderClose.vue"
// import iconquote from "./components/IconQuote.vue"
// import iconarrowleftblack from "./components/IconArrowLeftBlack.vue"

// animate
// import graphicstat from "./components/GraphicStat.vue"
import loopvideo from "./components/LoopVideo.vue"
import weather from "./components/Weather.vue"
import elmheight from "./components/ElmHeight.vue"
// import elmheightparallax from "./components/ElmHeightParallax.vue"
// import earthnet from "./components/EarthNet.vue"

// nav
// import mobilenav from "./components/MobileNav.vue"

// Form Validation
import formerrors from "./components/FormErrors.vue"

// Hero video
// import herovideo from "./components/HeroVideo.vue"
// import modal from "./components/Modal.vue"
import headervideoimage from "./components/HeaderVideoImage.vue"

// import carousel from "./components/Carousel.vue"
// import animatedtext from "./components/AnimatedText.vue"
// import dot from "./components/DotContent.vue"
// import instafeed from "./components/DotInstaFeed.vue"

// Utilities
import backgroundimage from "./components/BackgroundImage.vue"
// import daysuffix from "./components/DaySuffix.vue"
import vueimage from "./components/VueImage.vue"

//dot controls
// import dotcluster from "./components/DotCluster.vue"

// Vue.directive("scroll", {
//   inserted: function(el, binding) {
//     let f = function(evt) {
//       if (binding.value(evt, el)) {
//         window.removeEventListener("scroll", f)
//       }
//     }
//     window.addEventListener("scroll", f)
//   }
// })

console.log('bop')


/* eslint-disable no-new */
new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  components: {
    // blinklogo,
    // linkedin,
    // twitter,
    // iconinstagram,
    // facebook,
    // dribbble,
    // behance,
    // iconclose,
    // iconarrowright,
    // mobilenav,
    // iconsearch,
    // iconarrowrightblack,
    // iconarrowcircle,
    // iconchevronarrowdown,
    // graphicstat,
    loopvideo,
    weather,
    // iconplus,
    // herovideo,
    headervideoimage,
    // carousel,
    // animatedtext,
    elmheight,
    // elmheightparallax,
    // earthnet,
    // iconmenu,
    // iconquote,
    // dot,
    // instafeed,
    // dotcluster,
    // iconborderclose,
    Parallax,
    // closebutton,
    // modal,
    formerrors,
    backgroundimage,
    // iconarrowleftblack,
    // daysuffix,
    vueimage
  },
  data() {
    return {
      privateState: {},
      sharedState: s.state,
      showModal: false,
      currentOpen: "",
      currentCategory: "all",
      scroll: 0,
      largestScrollPos: 0,
      timer: 0,
      /* Form Validation */

      errors: [],
      name: null,
      firstName: null,
      lastName: null,
      email: null,
      emailSubscribe: null,
      websiteSubscribe: null
    }
  },
  beforeMount() {
    // if (this.$el.querySelector('[ref="email"]')) {
    //   this.email = this.$el.querySelector('[ref="email"]').value;
    // }
  },
  created() {
    // this.checkScrollPosition()
    // update the value attribute for input
    // for not empty style
    document.addEventListener("keyup", function(event) {
      // check wether it is a input element
      // if it is an input element, we set the attribute value to its value
      if (event.target.tagName === "INPUT") {
        event.target.setAttribute("value", event.target.value)
      }
    })

    if (window.DotGrid) {
      window.DotGrid.onReady(dotGrid => {
        s.setDotGrid(dotGrid)
      })
      window.DotGrid.onScroll(this.checkScrollPosition)
    } else {
      window.addEventListener("scroll", this.checkScrollPosition, true)
    }

    // The animationCount variable is set in the layout.twig file
    if (window.animationCount > 0) {
      this.preventDoubleAnimation()
    }

    // Close topics dropdown on mobile in ideas
    if (
      document.querySelector(".js-filter-dropdown") &&
      window.matchMedia("(max-width: 1439px)").matches
    ) {
      document.addEventListener("touchend", function(event) {
        let elm = document.querySelector(".js-filter-dropdown")
        if (event.target !== elm && !elm.contains(event.target)) {
          document
            .querySelector(".js-filter-dropdown .filter-dropdown__list")
            .setAttribute("style", "visibility: hidden;")
          document
            .querySelector(".js-filter-dropdown")
            .setAttribute("style", "border-bottom: 1px solid black;")
        }
        if (event.target == elm && elm.contains(event.target)) {
          document
            .querySelector(".js-filter-dropdown .filter-dropdown__list")
            .setAttribute("style", "visibility: visible;")
          document
            .querySelector(".js-filter-dropdown")
            .setAttribute("style", "border-bottom: 2px solid black;")
        }
      })
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.checkScrollPosition, true)
  },
  methods: {
    /* Form Validation */
    checkForm: function(e) {
      this.errors = []

      if (!this.firstName) {
        this.errors.push("Your first name cannot be blank.")
      }
      if (!this.lastName) {
        this.errors.push("Your last name cannot be blank.")
      }
      if (!this.email) {
        this.errors.push("Your Email cannot be blank.")
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.")
      }

      if (
        !this.errors.length &&
        this.emailSubscribe === null &&
        this.websiteSubscribe === null
      ) {
        return true
      }

      e.preventDefault()
    },

    /* Form Validation */
    checkFormSubscribe: function(e) {
      this.errors = []

      if (!this.firstName) {
        this.errors.push("Your first name cannot be blank.")
      } else {
        let error = this.errors.indexOf("Your first name cannot be blank.")
        this.errors.splice(error, 1)
      }
      if (!this.lastName) {
        this.errors.push("Your last name cannot be blank.")
      }
      if (!this.email) {
        this.errors.push("Your Email cannot be blank.")
      } else if (!this.validEmail(this.email)) {
        let error = this.errors.indexOf("Your Email cannot be blank.")
        this.errors.splice(error, 1)
        this.errors.push("Valid email required.")
      }

      if (
        !this.errors.length &&
        this.emailSubscribe === null &&
        this.websiteSubscribe === null
      ) {
        return true
      }

      e.preventDefault()
    },

    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    /* About */
    setOpen: function(name) {
      if (this.currentOpen === name) {
        this.currentOpen = ""
      } else {
        this.currentOpen = name
      }
    },
    /* About */
    setCategory: function(category) {
      this.currentCategory = category
    },

    setBoarderActive: function(event) {
      let parrent = event.target.parentElement.parentElement
      parrent.setAttribute("style", "border-bottom: 2px solid black;")
    },

    removeBoarderActive: function(event) {
      let parrent = event.target.parentElement.parentElement
      parrent.setAttribute("style", "border-bottom: 1px solid black;")
    },

    setBoarderBottomActive: function(event) {
      let parrent = event.target.parentElement
      parrent.setAttribute("style", "border-bottom: 2px solid black;")
    },

    removeBoarderBottomActive: function(event) {
      let parrent = event.target.parentElement
      parrent.setAttribute("style", "border-bottom: 1px solid black;")
    },

    /*
     * Remove classname that will cause a double css animation,
     * because vue will trigger a DOM update again when it loads 
     */
    preventDoubleAnimation() {
      const className = "has-page-transition"
      const elm = document.querySelector(`.${className}`)

      if (elm) {
        elm.classList.remove(className)
      }
    },
    checkScrollPosition() {
      this.scroll = window.pageYOffset
    }
  }
})