import EmblaCarousel from 'embla-carousel';
import { addDotBtnsAndClickHandlers, addPrevNextBtnsClickHandlers } from './embla-utils';
// import Player from '@vimeo/player';
// import ClassNames from 'embla-carousel-class-names'

if (document.querySelector('.ideas-2023')) {
    console.log('ideas-2023.js loaded')


    //podcasts row
    if (document.querySelector('.ideas-row.podcasts')) {
        const ideas2023Podcasts = document.querySelector('.ideas-row.podcasts');
        const ideas2023PodcastsEmblaWrapper = ideas2023Podcasts.querySelector('.ideas-row-inner');
        const ideas2023PodcastsEmblaSlides = ideas2023Podcasts.querySelectorAll('.ideas-row-inner .podcast');
        const ideas2023PodcastsPrevBtn = ideas2023Podcasts.querySelector('.ideas-2023__prev');
        const ideas2023PodcastsNextBtn = ideas2023Podcasts.querySelector('.ideas-2023__next');
        const ideas2023PodcastsDots = ideas2023Podcasts.querySelector('.embla__dots');
        const ideas2023PodcastsEmbla = EmblaCarousel(ideas2023PodcastsEmblaWrapper, {
            loop: false,
            // dragFree: true,
            slidesToScroll: 1,
            // containScroll: 'trimSnaps',
            align: 'start',
            speed: 1,
            containScroll: 'trimSnaps',
            dragFree: true,
            breakpoints: {
                '(min-width: 768px)': { slidesToScroll: 3 },
              },
        });

        // console.log(ideas2023PodcastsEmbla);

        // const runSelect = () => {
        //     console.log('select');
        //     document.querySelectorAll('.ideas-2023 .podcasts .is-selected').forEach((item) => {
        //         item.classList.remove('is-selected');
        //     });
        //     let selected = ideas2023PodcastsEmbla.selectedScrollSnap();
        //     ideas2023PodcastsEmblaSlides[selected].classList.add('is-selected');
        //     console.log(selected);
        // }

        const removePodcastsDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            ideas2023PodcastsEmbla,
            ideas2023PodcastsDots
          )

          const removePodcastsPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            ideas2023PodcastsEmbla,
            ideas2023PodcastsPrevBtn,
            ideas2023PodcastsNextBtn
          )

        // ideas2023PodcastsEmbla.on('init', runSelect);
        // ideas2023PodcastsEmbla.on('select', runSelect);
    }

    //videos row
    if (document.querySelector('.ideas-row.videos')) {
        const ideas2023Videos = document.querySelector('.ideas-row.videos');
        const ideas2023VideosEmblaWrapper = ideas2023Videos.querySelector('.ideas-row-inner');
        const ideas2023VideosEmblaSlides = ideas2023Videos.querySelectorAll('.ideas-row-inner .video');
        const ideas2023VideosPrevBtn = ideas2023Videos.querySelector('.ideas-2023__prev');
        const ideas2023VideosNextBtn = ideas2023Videos.querySelector('.ideas-2023__next');
        const ideas2023VideosDots = ideas2023Videos.querySelector('.embla__dots');
        const ideas2023VideosEmbla = EmblaCarousel(ideas2023VideosEmblaWrapper, {
            loop: false,
            slidesToScroll: 1,
            align: 'start',
            speed: 1,
            containScroll: 'trimSnaps',
            dragFree: true,
            breakpoints: {
                '(min-width: 768px)': { slidesToScroll: 3},
              },
        });

        // console.log(ideas2023VideosEmbla);

        // const runSelect = () => {
        //     console.log('select');
        //     document.querySelectorAll('.ideas-2023 .videos .is-selected').forEach((item) => {
        //         item.classList.remove('is-selected');
        //     });
        //     let selected = ideas2023VideosEmbla.selectedScrollSnap();
        //     ideas2023VideosEmblaSlides[selected].classList.add('is-selected');
        //     console.log(selected);
        // }
        const removeVideosDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            ideas2023VideosEmbla,
            ideas2023VideosDots
          )

          const removeVideosPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            ideas2023VideosEmbla,
            ideas2023VideosPrevBtn,
            ideas2023VideosNextBtn
          )

        // ideas2023VideosEmbla.on('init', runSelect);
        // ideas2023VideosEmbla.on('select', runSelect);
    }

    //articles row
    if (document.querySelector('.ideas-row.articles:not(.news)')) {
        let allTags = document.querySelectorAll('.tag-cloud__tag.js');
        let allArticles = document.querySelectorAll('.ideas-row.articles:not(.news) .article');

        const ideas2023Articles = document.querySelector('.ideas-row.articles:not(.news)');
        const ideas2023ArticlesEmblaWrapper = ideas2023Articles.querySelector('.ideas-row-inner');
        const ideas2023ArticlesEmblaSlides = ideas2023Articles.querySelectorAll('.ideas-row-inner .article');
        const ideas2023ArticlesPrevBtn = ideas2023Articles.querySelector('.ideas-2023__prev');
        const ideas2023ArticlesNextBtn = ideas2023Articles.querySelector('.ideas-2023__next');
        const ideas2023ArticlesDots = ideas2023Articles.querySelector('.embla__dots');
        const ideas2023ArticlesOptions = {
            loop: false,
            slidesToScroll: 1,
            containScroll: 'trimSnaps',
            align: 'start',
            speed: 1,
            slides: '.ideas-row.articles:not(.news) .article:not(.is-hidden)',
            dragFree: true,
            breakpoints: {
                '(min-width: 768px)': { slidesToScroll: 3 },
              },
        };
        let ideas2023ArticlesEmbla = EmblaCarousel(ideas2023ArticlesEmblaWrapper, ideas2023ArticlesOptions);
        const removeArticlesDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            ideas2023ArticlesEmbla,
            ideas2023ArticlesDots
          )

          const removeArticlesPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            ideas2023ArticlesEmbla,
            ideas2023ArticlesPrevBtn,
            ideas2023ArticlesNextBtn
          )
        // console.log(ideas2023ArticlesEmbla);

        for (let i = 0; i < allTags.length; i++) {
            allTags[i].addEventListener('click', function () {
                document.querySelector('.tag-cloud__tag.active').classList.remove('active');
                this.classList.add('active');
                let tag = ' ' + this.dataset.blinkTag + ' ';

                if (tag == ' all ') {

                    for (let j = 0; j < allArticles.length; j++) {
                        if (j <= 12) {
                            allArticles[j].classList.remove('is-hidden');
                        } else {
                            allArticles[j].classList.add('is-hidden');
                        }
                    }
                    } else {
                // console.log(tag);
                for (let j = 0; j < allArticles.length; j++) {
                    if (allArticles[j].dataset.blinkTags.includes(tag)) {
                        allArticles[j].classList.remove('is-hidden');
                    } else {
                        allArticles[j].classList.add('is-hidden');
                    }
                }
            }
                ideas2023ArticlesEmbla.reInit();
                // ideas2023ArticlesEmbla.index.set(0);
                // ideas2023ArticlesEmbla.animation.start();
                // ideas2023ArticlesEmbla = EmblaCarousel(ideas2023ArticlesEmblaWrapper, ideas2023ArticlesOptions);
            })
        }
    }

        //News row
        if (document.querySelector('.ideas-row.articles.news')) {
            // let allTags = document.querySelectorAll('.tag-cloud__tag.js');
            let allNews = document.querySelectorAll('.ideas-row.articles.news .article');
    
            const ideas2023News = document.querySelector('.ideas-row.articles.news');
            const ideas2023NewsEmblaWrapper = ideas2023News.querySelector('.ideas-row-inner');
            const ideas2023NewsEmblaSlides = ideas2023News.querySelectorAll('.ideas-row-inner .article');
            const ideas2023NewsPrevBtn = ideas2023News.querySelector('.ideas-2023__prev');
            const ideas2023NewsNextBtn = ideas2023News.querySelector('.ideas-2023__next');
            const ideas2023NewsDots = ideas2023News.querySelector('.embla__dots');
            const ideas2023NewsOptions = {
                loop: false,
                slidesToScroll: 1,
                containScroll: 'trimSnaps',
                align: 'start',
                speed: 1,
                slides: '.ideas-row.articles.news .article:not(.is-hidden)',
                dragFree: true,
                breakpoints: {
                    '(min-width: 768px)': { slidesToScroll: 3 },
                  },
            };
            let ideas2023NewsEmbla = EmblaCarousel(ideas2023NewsEmblaWrapper, ideas2023NewsOptions);
            const removeNewsDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
                ideas2023NewsEmbla,
                ideas2023NewsDots
              )

              const removeNewsPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
                ideas2023NewsEmbla,
                ideas2023NewsPrevBtn,
                ideas2023NewsNextBtn
              )
            // console.log(ideas2023NewsEmbla);
        }

    //events row
    if (document.querySelector('.ideas-row.events')) {
        const ideas2023Events = document.querySelector('.ideas-row.events');
        const ideas2023EventsEmblaWrapper = ideas2023Events.querySelector('.ideas-row-inner');
        const ideas2023EventsEmblaSlides = ideas2023Events.querySelectorAll('.ideas-row-inner .article');
        const ideas2023EventsPrevBtn = ideas2023Events.querySelector('.ideas-2023__prev');
        const ideas2023EventsNextBtn = ideas2023Events.querySelector('.ideas-2023__next');
        const ideas2023EventsDots = ideas2023Events.querySelector('.embla__dots');
        const ideas2023EventsOptions = {
            loop: false,
            slidesToScroll: 1,
            align: 'start',
            speed: 1,
            containScroll: 'trimSnaps',
            dragFree: true,
            breakpoints: {
                '(min-width: 768px)': { slidesToScroll: 3 },
              },
        };
        let ideas2023EventsEmbla = EmblaCarousel(ideas2023EventsEmblaWrapper, ideas2023EventsOptions);
        const removeEventsDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            ideas2023EventsEmbla,
            ideas2023EventsDots
          )

          const removeEventsPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            ideas2023EventsEmbla,
            ideas2023EventsPrevBtn,
            ideas2023EventsNextBtn
          )
          
    }

    //featured row
    if (document.querySelector('.ideas-row.featured')) {
        const ideas2023Featured = document.querySelector('.ideas-row.featured');
        const ideas2023FeaturedEmblaWrapper = ideas2023Featured.querySelector('.ideas-row-inner');
        const ideas2023FeaturedEmblaSlides = ideas2023Featured.querySelectorAll('.ideas-row-inner .featured');
        const ideas2023FeaturedPrevBtn = ideas2023Featured.querySelector('.ideas-2023__prev');
        const ideas2023FeaturedNextBtn = ideas2023Featured.querySelector('.ideas-2023__next');
        const ideas2023FeaturedDots = ideas2023Featured.querySelector('.embla__dots');
        const ideas2023FeaturedEmbla = EmblaCarousel(ideas2023FeaturedEmblaWrapper, {
            loop: false,
            slidesToScroll: 1,
            align: 'start',
            speed: 1,
            containScroll: 'trimSnaps',
            dragFree: true,
            breakpoints: {
                '(min-width: 768px)': { slidesToScroll: 3 },
                },
        });

        // console.log(ideas2023FeaturedEmbla);

        const removeFeaturedDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            ideas2023FeaturedEmbla,
            ideas2023FeaturedDots
            )


        const removeFeaturedPrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
            ideas2023FeaturedEmbla,
            ideas2023FeaturedPrevBtn,
            ideas2023FeaturedNextBtn
          )
        }

    // vimeo shared. this is hacky to deal with a dev issue. TODO: clean up

    if (document.querySelector('.has-vimeo:not(.hover-play)')) {
        // console.log('has vimeo....')
        document.querySelectorAll('.has-vimeo:not(.hover-play)').forEach((item) => {
            // console.log('it', item)
            let videoArea = item.querySelector('.idea-card-image');
            let video = item.querySelector('iframe');
            let wrapper = item.querySelector('.iframe-wrapper');
            let hasPlayed = false;
            console.log('not hoverplay');
            // console.log('vid', video, 'wrap', wrapper);
            if (video == null) return;
            // console.log('it wrapper', wrapper)

            // let player = new Player(video);

            // let togglePlay = () => {
            //     if (!hasPlayed) {
            //         wrapper.classList.remove('unplayed');
            //         hasPlayed = true;
            //     }
            
            //     let isPaused = player.getPaused().then(function(paused) {
            //         if (paused) {
            //             player.play();
            //         } 
            //         // else {
            //         //     player.pause();
            //         // }
            //     }); 
            // }

            // console.log('wrap', wrapper);
            wrapper.addEventListener('click', togglePlay)

        })
    }

    if (document.querySelector('.has-vimeo.hover-play')) {
        document.querySelectorAll('.has-vimeo.hover-play').forEach((item) => {
            // console.log('yip', item)
            console.log('hoverplay', item);
            let videoArea = item.querySelector('.idea-card-image');
            let video = item.querySelector('iframe');
            let wrapper = item.querySelector('.iframe-wrapper');

            if (video == null) {
                // console.log('no video', item);
                return;
            };
            let player = new Player(video);

            let togglePlayOnHover = () => {

                if (wrapper.classList.contains('unplayed')) {
                    player.play();
                } else {
                    player.pause();
                }
                wrapper.classList.toggle('unplayed');
            }

            let toggleMute = () => {
                player.getMuted().then((muted) => {
                    if (muted) {
                        player.setMuted(false);
                    } else {
                        player.setMuted(true);
                    }
                });
            }



            videoArea.addEventListener('mouseenter', togglePlayOnHover);
            videoArea.addEventListener('mouseleave', togglePlayOnHover);
            video.addEventListener('click', toggleMute);

        
        });


    }

    // if (document.querySelector('.idea-card.featured-videos')) {
    //     document.querySelectorAll('.idea-card.featured-videos').forEach((item) => {
    //         // console.log('yip', item)
    //         let videoArea = item.querySelector('.idea-card-image');
    //         let video = item.querySelector('iframe');
    //         let wrapper = item.querySelector('.iframe-wrapper');
    //         let player = new Player(video);

    //         let togglePlayOnHover = () => {

    //             if (wrapper.classList.contains('unplayed')) {
    //                 player.play();
    //             } else {
    //                 player.pause();
    //             }
    //             wrapper.classList.toggle('unplayed');
    //         }

    //         let toggleMute = () => {
    //             player.getMuted().then((muted) => {
    //                 if (muted) {
    //                     player.setMuted(false);
    //                 } else {
    //                     player.setMuted(true);
    //                 }
    //             });
    //         }



    //         videoArea.addEventListener('mouseenter', togglePlayOnHover);
    //         videoArea.addEventListener('mouseleave', togglePlayOnHover);
    //         video.addEventListener('click', toggleMute);

        
    //     });


    // }

    if (document.querySelector('.transcript')) {
        let transcriptSection = document.querySelector('.transcript-wrap');
        let transcript = transcriptSection.querySelector('.transcript');
        let transcriptReadMore = transcriptSection.querySelector('.transcript-read-more');

        transcriptSection.addEventListener('click', function() {
            transcript.classList.toggle('truncate-5-lines');
            transcript.classList.toggle('truncate-3-lines-desktop');
            transcriptReadMore.classList.toggle('hidden');
        }
        )
    }
}