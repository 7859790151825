// import { csDebounce } from "../cs2023"
// import throttle from "lodash/throttle"

const scaleCta = document.querySelector(".scale-cta")
const bg = document.querySelector(".scale-cta-bg")

if (scaleCta) {
//   let shrinkOnScroll = throttle(
//     function () {
//       let percentageFromBottom =
//         (scaleCta.getBoundingClientRect().bottom / window.innerHeight) * 100
//       let percentageScrolledPast = 100 - percentageFromBottom
//       let finalPercent = percentageScrolledPast * 0.8
//       let radiusPercent = (16 * percentageScrolledPast) / 50
//       bg.style.inset = finalPercent + "px"
//       bg.style.borderRadius = radiusPercent + "px"
//     },
//     5,
//     { leading: true, trailing: true }
//   )

function limitNumberWithinRange(num, min, max){
  const MIN = min ?? 1;
  const MAX = max ?? 20;
  const parsed = parseInt(num)
  return Math.min(Math.max(parsed, MIN), MAX)
}

function shrinkOnScroll() {
    let percentageFromBottom =
      parseInt(((scaleCta.getBoundingClientRect().bottom - 400) / window.innerHeight) * 100);
    let percentageScrolledPast = 100 - percentageFromBottom;
    let outOfFourty = limitNumberWithinRange(40 * (percentageScrolledPast / 100), 0, 40);
    let outOfTwelve = limitNumberWithinRange(12 * (percentageScrolledPast / 100), 0, 12);

    let finalInset = 40 - outOfFourty;
    let finalRadius = 12 - outOfTwelve;

    bg.style.inset = finalInset + "px"
    bg.style.borderRadius = finalRadius + "px"
  };

  function scaleCtaCallback(entries, observer) {
    if (window.innerWidth < 1024) {
      return
    }

    entries.forEach((entry) => {
      console.log(entry)
      if (entry.isIntersecting) {
        window.addEventListener("scroll", shrinkOnScroll)
      } else {
        window.removeEventListener("scroll", shrinkOnScroll)
      }
    })
  }
  let scaleCtaObserver = new IntersectionObserver(scaleCtaCallback)
  scaleCtaObserver.observe(scaleCta)
}
