import Swiper from 'swiper/bundle';

var CareerOffersSlider = new Swiper("#CareerOffers", {

    centeredSlides: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    // Navigation arrows
    navigation: { 
        nextEl: '.right',
        prevEl: '.left',
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,

        },
        960: {
            slidesPerView: 2,
            spaceBetween: 25,
        },
      }
});

var CareerSocialSlider = new Swiper("#CareerSocial", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    // Navigation arrows
    navigation: {
        nextEl: '.right',
        prevEl: '.left',
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            allowTouchMove: true
        },
        960: {
            slidesPerView: "auto",
            allowTouchMove: false
        },
    },
    spaceBetween: 8,
    setWrapperSize: true,

});

var CareerInternshipSlider = new Swiper("#CareerInternship", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    // Navigation arrows
    navigation: {
        nextEl: '.right',
        prevEl: '.left',
    },
    allowTouchMove: false
});


var CareerValuesSlider = new Swiper("#CareerValues", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletClass: 'swiper-button',
        bulletActiveClass: 'active',
        renderBullet: function (index, className) {
          return '<button class="' + className + '"><h4>' + (index + 1) + '</h4><h4>' + this.slides[index].dataset.title +'</h4></button>';
        },
    },
    navigation: {
        nextEl: '.right',
        prevEl: '.left',
    },
    allowTouchMove: false
});